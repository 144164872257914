.create-warehouse-container {
	margin: 0 10px 10px 10px;
	height: auto;
	display: flex;
	flex-direction: column;
	padding-bottom: 10px;
}

.create-warehouse-form-items {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin: 10px;
}

.create-warehouse-form-items-select {
	width: 260px;
}

.create-warehouse-form-action-button {
	display: flex;
	align-items: center;
	justify-content: center;
}

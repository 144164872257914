/* Foreground */
@value _primaryColor: #FFF;
@value _mediumEmphasisColor: rgba(255, 255, 255, .87);
@value _highEmphasisColor: #FFFFFF;
@value _secondaryColor: #1EB980;
@value _inactiveColor: #FFFFFF8A;
@value _warningColor: #FF0000;
/* Background */
@value _primaryBackgroundColor: #1F1B24;
@value _secondaryBackgroundColor: #2D2D2D;
@value _surfaceBackgroundColor: #FFFFFF1F;
@value _transparentBackgroundColor: transparent;
@value _disabledBackgroundColor: rgb(51, 51, 51);
@value _tranparentOverlayBackgroundColor: rgba(0, 0, 0, 0.6);
/* Border */
@value _borderColor: #007A46;

/* Foreground */
.primaryColor {
	color: _primaryColor;
}
.mediumEmphasisColor {
	color: _mediumEmphasisColor;
}
.highEmphasisColor {
	color: _highEmphasisColor;
}
.secondaryColor {
	color: _secondaryColor;
}
.inactiveColor {
	color: _inactiveColor;
}
.warningColor {
	color: _warningColor;
}

/* Background */
.primaryBackgroundColor {
	background-color: _primaryBackgroundColor;
}
.secondaryBackgroundColor {
	background-color: _secondaryBackgroundColor;
}
.surfaceBackgroundColor {
	background-color: _surfaceBackgroundColor;
}
.disabledBackgroundColor {
	background-color: _disabledBackgroundColor;
}
.transparentBackgroundColor {
	background-color: _transparentBackgroundColor;
}

/* Border */
.borderColor {
	border-color: _borderColor;
}

/* Events */

.hoverable:hover {
	color: _secondaryColor;
	cursor: pointer;
}
.focus:focus {
	color: _secondaryColor;
}
.required:after {
	content: ' *';
}
.required {
	composes: warningColor;
}

.container-login {
	background-color: transparent;
}

.new-password-login-form {
	height: 225px;
}

.new-password-margin-input {
	margin-top: 6px;
}

.container-login-form {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.container-login-form-action-buttons {
	max-width: 260px;
	height: 65px;
	display: flex;
	justify-content: space-between;
	flex-direction: column;
	align-items: center;
}

.label-input-password {
	margin-left: 6px;
}

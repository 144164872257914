.create-path-container {
	position: relative;
	top: 12px;
	margin: 10px 10px;
	height: auto;
	max-width: 600px;
	display: flex;
	flex-direction: column;
	padding: 20px;
	background-color: #3f3f49;
}

.create-path-title {
	width: 214.7px;
	height: 24px;
	font-family: Roboto;
	font-size: 20.2px;
	font-weight: 500;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: 0.25px;
	color: #ffffff;
}

.create-path-form-items {
	margin: 10px 0 15px 0;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: center;
}

.create-path-form-action-button {
	display: flex;
	align-items: center;
	justify-content: center;
}

@media (min-width: 600px) {
	.create-path-form-items {
		justify-content: start;
	}
	.create-path-form-action-button {
		margin-right: 10px;
		justify-content: flex-end;
	}
}

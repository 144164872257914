.WrapperStyled {
	position: unset;
	width: auto;
	max-width: unset;
	min-width: unset;
	height: auto;
	min-height: unset;
	max-height: unset;
	margin: 0px;
	padding: 16px;
	display: flex;
	flex-direction: row;
	flex-wrap: unset;
	justify-content: flex-start;
	align-items: center;
	overflow: unset;
	border-radius: unset;
	box-shadow: unset;
	transition: unset;
	cursor: unset;
}

.container {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	width: 100%;
	height: 100%;
	min-height: 25px;
	overflow: hidden;
}
.containerDark {
	composes: container;
}
.containerLight {
	composes: container;
}

/* ------------------------------------------------------------- */
.containerDropdownLight {
	composes: container;
	border-radius: 30px;
	border: 1px solid rgba(46, 125, 50, 0.54);
}
.containerDropdownLight span {
	color: rgba(46, 125, 50, 1);
}

.containerDropdownDark {
	composes: container;
	border-radius: 30px;
	border: 1px solid rgba(46, 125, 50, 0.54);
}
.containerDropdownDark span {
	color: rgba(46, 125, 50, 1);
}

/* ------------------------------------------------------------- */

.containerSelectLight {
	composes: container;
}
.containerSelectDark {
	composes: container;
}

#pay_meta-card-container {
	padding: 10px;
	background: #3f3f49;
	height: 210px;
}

.pay-container-data-input {
	display: flex;
	justify-content: space-between;
	margin-bottom: 5px;
}

.pay-data {
	width: 60%;
	display: flex;
	flex-direction: column;
	margin: 0;
	padding: 0 0 10px 0;
}

.pay-data-right {
	width: 40%;
	display: flex;
	flex-direction: column;
	margin: 0;
	padding: 0 0 10px 0;
	overflow: auto;
}

.pay-container-title-card {
	display: flex;
	flex-direction: column;
	margin-bottom: 15px;
}

.pay-body-container-item {
	width: 73vw;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
}

.pay-body-concept-item {
	font-size: 12px;
	font-family: 'Roboto';
	color: silver;
	margin-right: 10px;
}

.pay-card-document {
	font-size: 13px;
	font-family: 'Roboto';
	color: white;
}

.pay-card-creator {
	font-size: 12px;
	font-family: 'Roboto';
	color: lightgrey;
}

.pay-card-creator-clickeable {
	font-size: 13px;
	font-family: 'Roboto';
	color: white;
}

.pay-card-creator-clickeable:active {
	color: lightskyblue;
}

.pay-card-created_at {
	font-family: 'Roboto';
	font-size: 12px;
	color: darkgray;
}

.pay-buttons-container {
	width: 100%;
	display: flex;
	justify-content: space-evenly;
	align-items: center;
}

.pay-flag-payment-state {
	margin-top: 5px;
	min-width: 5px;
	height: 40px;
	position: fixed;
}

.pay-popover-list {
	list-style: none;
	padding: 7px;
	color: white;
	background-color: #2c2c36;
	margin: 0;
}

.pay-lines-label {
	font-size: 13px;
	font-family: Roboto;
	color: white;
}

#pay-popover-item {
	font-family: Roboto;
	height: 30px;
	padding: 5px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	cursor: pointer;
	color: white;
}

#pay-popover-item:active {
	color: lightskyblue;
}

#pay-popover-item-disabled {
	color: gray;
	padding: 5px;
}

#paycut_resume_meta-card-container {
	height: 180px;
}

.paycut_resume-container-data-input {
	display: flex;
	justify-content: space-between;
}

.paycut_resume-data {
	width: 50%;
	display: flex;
	flex-direction: column;
	margin: 0;
}

.paycut_resume-container-title-card {
	display: flex;
	flex-direction: column;
	margin-bottom: 10px;
}

.paycut_resume-body-container-item {
	display: flex;
	flex-wrap: wrap;
	width: 73vw;
}

.paycut_resume-body-concept-item {
	font-size: 12px;
	font-family: 'Roboto';
	color: silver;
	margin-right: 10px;
}

.paycut_resume-body-concept-item-total {
	font-size: 12px;
	font-family: 'Roboto';
	color: burlywood;
	margin-right: 10px;
}

.paycut_resume-card-document {
	font-size: 13px;
	font-family: 'Roboto';
	color: white;
}

.paycut_resume-card-creator {
	font-size: 12px;
	font-family: 'Roboto';
	color: lightgrey;
}

.paycut_resume-card-creator-clickeable {
	font-size: 13px;
	font-family: 'Roboto';
	color: white;
}

.paycut_resume-card-creator-clickeable:active {
	color: lightskyblue;
}

.paycut_resume-card-created_at {
	font-family: 'Roboto';
	font-size: 12px;
	color: darkgray;
}

.paycut_resume-buttons-container {
	display: flex;
	width: 100%;
	justify-content: space-between;
}

.paycut_resume-flag-payment-state {
	margin-top: 5px;
	min-width: 5px;
	height: 40px;
	position: fixed;
}

.postWrapper {
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	overflow: hidden;
	box-shadow:
		0px 2px 4px rgba(7, 6, 6, 0.14),
		0px 3px 4px rgba(0, 0, 0, 0.12),
		0px 1px 5px rgba(0, 0, 0, 0.2);
}

.postInfo {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	padding-bottom: 5px;
}

.top {
	display: flex;
	flex-wrap: wrap;
	padding: 10px 8px 5px 16px;
}

/* CARD VIEW */
.galleryWrapperCard {
	width: 200px;
	/* height: 100%; */
	max-height: 142px;
}

.galleryWrapperCardDesktop {
	composes: galleryWrapperCard;
}

.galleryWrapperCardMobile {
	composes: galleryWrapperCard;
}

@media screen and (max-width: 930px) {
	.galleryWrapperCardDesktop {
		display: none;
	}
}

@media screen and (max-width: 610px) {
	.galleryWrapperCardMobile {
		display: none;
	}
}

.postWrapperCard {
	composes: postWrapper;
	display: flex;
	height: 100%;
	flex-direction: row;
	justify-content: space-between;
}

.infoWrapper {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	width: 100%;
}

@import url('https://fonts.googleapis.com/css?family=Chivo');
@import url('https://fonts.googleapis.com/css?family=Chivo|Philosopher');

.card-content {
	padding: 2px 0 3px 3px !important;
}

.card-head-title {
	font-family: 'Philosopher', sans-serif;
	font-weight: bold;
	font-size: 1.6em;
	display: block;
	padding: 0 5px;
	margin: 2px 0;
}

.card-head-div-lectura {
	background: #b3e5fc;
	padding-top: 4px;
	padding-bottom: 4px;
}

.card-head-div-recibidas {
	background: #ffffff;
	padding-top: 4px;
	padding-bottom: 4px;
}

.card-head-div-procesadas {
	background: #b3e5fc;
	padding-top: 4px;
	padding-bottom: 4px;
}

.card-item-text {
	color: black;
	font-family: 'Chivo', sans-serif;
	font-size: 1.2em;
}

.card-item-div {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	padding: 5px 10px;
}

.dropdown {
	position: relative;
	display: flex;
	width: 15px;
	height: 40px;
}

.container {
	position: absolute;
	right: 0;
	height: fit-content;
	width: 228px;
	display: flex;
	flex-direction: column;
	box-sizing: border-box;
	box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
	z-index: 30;
}

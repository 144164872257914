.popoverContent {
	width: 135px;
}

.buttonContentWrapper {
	display: flex;
	align-items: center;
	justify-content: center;
}

.buttonLabel {
	text-transform: uppercase;
	letter-spacing: 0.75px;
	font-size: 12px;
	font-weight: bold;
	color: rgba(255, 255, 255, 0.6);
}

.buttonLabelActive {
	composes: buttonLabel;
	color: #007a46;
}
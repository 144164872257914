.defaultFormContainer {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
}

.defaultComponentContainer {
	width: 100%;
	display: flex;
	justify-content: center;
	margin-top: 16px;
}

.noneDisplay {
	display: none;
}

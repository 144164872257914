.container {
	width: 100%;
	background-color: #242526;
	border-top: 1px solid grey;
	color: whitesmoke;
	max-height: 250px;
	overflow-y: scroll;
}

.eventContainer {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
}

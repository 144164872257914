.ant-table-thead > tr > th {
	color: white;
	background: transparent;
}

.ant-table-header {
	background-color: transparent;
}

tr.ant-table-row {
	color: white;
}

.ant-table-fixed-header
	> .ant-table-content
	> .ant-table-scroll
	> .ant-table-body {
	background: transparent;
}

.ant-table-thead
	> tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)
	> td,
.ant-table-tbody
	> tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)
	> td {
	background: #e6f7ff;
	color: #000;
}

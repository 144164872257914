.Upload span {
	color: #eee;
}

.ant-upload.ant-upload-select-picture-card {
	background-color: transparent;
	background: transparent;
	color: #eee;
	font-weight: 600;
}

.ant-upload-list-picture .ant-upload-list-item-error,
.ant-upload-list-picture-card .ant-upload-list-item-error {
	border-color: #eee;
}
span .sc-eCApGN .LUtQJ {
	color: #21a777;
}
.inputfile + label svg {
	width: 1em;
	height: 1em;
	vertical-align: middle;
	fill: #fff;
	margin-top: -0.25em;
	margin-right: 0.25em;
}

.iborrainputfile {
	font-size: 16px;
	font-weight: normal;
	font-family: "Lato";
	fill: #fff;
}
.inputfile-1 + label {
	color: #fff;
}
.ant-divider-inner-text {
	display: inline-block;
	padding: 0 20px;
	font-size: 15px;
	font-weight: 700;
}

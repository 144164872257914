.menuContainer {
	padding: 10px;
	padding-top: 25px;
}

.mobileMenuContainer {
	composes: menuContainer;
	width: 100%;
}

.desktopMenuContainer {
	composes: menuContainer;
	overflow: auto;
	max-height: 100vh;
}

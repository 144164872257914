.subtoolbar-head {
	display: flex;
	justify-content: flex-end;
	align-items: center;
}

.subtoolbar-content {
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.transactions-subtoolbar-content {
	padding-left: 10px;
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.subtoolbar-subcontent-left {
	display: flex;
	justify-content: center;
	align-items: center;
}

.warehouses_list-subtoolbar-text {
	margin-left: 10px;
	font-family: Roboto;
	font-size: 16.2px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.48;
	letter-spacing: 0.15px;
	color: rgba(255, 255, 255, 0.6);
}

.subtoolbar-text {
	font-family: Roboto;
	font-size: 16.2px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.48;
	letter-spacing: 0.15px;
	color: rgba(255, 255, 255, 0.6);
}

.text-users-selected {
	height: 43px;
	color: white;
	display: flex;
	align-items: center;
}

.orders_my-assigned-zones-list-item {
	font-family: Roboto;
	font-size: 13px;
	height: 30px;
	padding: 5px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	cursor: pointer;
	color: white;
}

.orders_my-assigned-zones-list-item:hover {
	color: lightseagreen;
}

.orders_my-assigned-zones-list-item:active {
	color: lightskyblue;
}

.orders_my-assigned-zones-list-item-selected {
	background-color: #3f3f49;
}

.header-container-assign-to-expert {
	display: flex;
	flex-direction: column;
	overflow: auto;
	max-height: 500px;
}

.header-container-assign-to-expert::-webkit-scrollbar {
	display: none;
}

.header-panel-assign-to-expert-head {
	display: flex;
	justify-content: space-between;
	align-items: baseline;
}

.header-panel-assign-to-expert-title {
	font-size: 14px;
	font-family: 'Roboto';
	color: rgba(255, 255, 255, 0.6);
}

.subtitle-order-assign-user {
	font-size: 14px;
	color: white;
}

.item-order-assign-user-concept {
	color: rgba(255, 255, 255, 0.6);
}

.item-order-assign-user-value {
	color: white;
}

.date-custom {
	width: 100%;
	margin: 0 0 5px 0;
}

.select-custom {
	width: 100%;
	margin: 0 0 5px 0;
}

.checkbox-my-zones {
	width: 100%;
	margin: 5px 0 0 0;
}

.tab-content-information-order {
	height: 65vh;
	padding: 10px 10px;
	background-color: #3f3f49;
}

.sign_wtd-form-items {
	padding: 10px;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.container-information-order {
	height: 500px;
	display: flex;
	flex-wrap: wrap;
	padding: 10px 10px;
	color: rgba(255, 255, 255, 0.6);
}

.tabs-container {
	height: 100%;
	display: flex;
	flex-direction: column;
	padding: 10px 10px;
	color: rgba(255, 255, 255, 0.6);
}

.container-dark {
	height: 500px;
}

.container-search-orders-filters {
	display: flex;
	flex-direction: column;
	width: 100%;
}

.container-info-orders-income {
	display: flex;
	flex-direction: column;
	width: 100%;
	align-items: center;
}

.container-info-orders-income-dates {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.form-action-button {
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: 15px;
}

.form-items {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin: 10px;
}

.form-items-select {
	width: 260px;
}

.modal-container {
	margin: 0 10px 10px 10px;
	height: auto;
	display: flex;
	flex-direction: column;
}

.sign_wtd-container-signs {
	width: 100%;
	padding: 0 10px 10px 10px;
	display: flex;
}

.container-signs {
	width: 100%;
	display: flex;
}

.form-signs {
	margin: 10px 0;
	padding: 10px;
	border-radius: 20px;
	/* background-color: #2c2c36; */
}

.form-subhead-content {
	width: 100%;
	display: flex;
	justify-content: space-evenly;
	flex-direction: column;
	margin-bottom: 10px;
	color: rgba(255, 255, 255, 0.6);
	font-family: Roboto;
	font-size: 14.2px;
	font-weight: 500;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.69;
	letter-spacing: 0.2px;
	text-align: center;
}

.permissions-content {
	height: 40vh;
}

.permissions-content::-webkit-scrollbar {
	display: none;
}

.actions-container {
	display: grid;
	grid-template-columns: 1fr 1fr;
}

#save-permissions-button {
	position: absolute;
	bottom: 1%;
	left: 90%;
	background-color: #2c2c36;
}

@media (orientation: portrait) {
	.permissions-content {
		height: 37vh;
	}
	.action-checkbox {
		width: 35% !important;
	}
	#save-permissions-button {
		left: 86%;
	}
}

.control-mobile-title-head {
	display: flex;
	justify-content: center;
	align-items: center;
}

.control-mobile-title-list {
	display: flex;
	font-family: 'Roboto';
	align-items: center;
}

.control-mobile-wrap-list-draggable {
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	max-height: 490px;
	overflow: auto;
}

.control-mobile-wrap-list-draggable-sort {
	display: flex;
	flex-direction: column;
}

.control-mobile-wrap-list-draggable-reassign {
	display: flex;
	flex-direction: row;
	max-height: 590px;
	overflow: auto;
	flex-wrap: wrap;
}

.control-mobile-title-name {
	width: 80%;
	color: rgba(255, 255, 255, 0.9);
	font-family: 'Roboto';
	font-weight: bold;
}

.control-mobile-title-name-event {
	width: 95%;
	color: rgba(255, 255, 255, 0.9);
	font-family: 'Roboto';
}

.control-buttons-order {
	width: '5px';
	height: '5px';
	margin-bottom: '4px';
	top: '8px';
}

#container-control-buttons-order {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.table-orders-container {
	display: flex;
	flex-direction: column;
	border: black solid 1px;
	padding: 5px;
	box-shadow: 8px 4px 7px #888888;
}

.element-order-table {
	display: flex;
	justify-content: space-between;
}

.prop-order-table {
	font-weight: bold;
	font-size: smaller;
	font-family: 'Roboto';
	margin-right: 5px;
}

.value-order-table {
	font-size: medium;
	font-family: 'Roboto';
	text-align: right;
}

/* NEW DESIGN */

.order-card-container {
	width: 344px;
	height: 157px;
	border-radius: 8px;
	margin: 0 5px 5px 5px;
}

.order-card-container-no-dragging {
	background-color: #3f3f49;
}

.order-card-container-dragging {
	background-color: rgba(255, 255, 255, 0.16);
}

.color-flag {
	width: 4px;
	height: 40px;
}

.color-flag-reassign {
	width: 30px;
	height: 40px;
}

.order-card-head {
	display: flex;
}

.order-card-head-cardviews {
	padding: 0 5px 0 0;
	display: flex;
}

.order-card-subhead {
	display: flex;
	margin-left: 20px;
}

.order-card-subhead-cardviews {
	display: flex;
	margin-left: 8px;
}

.container-last-event-at {
	margin-right: 3px;
	text-align: end;
}

.last_event_at {
	color: cyan;
	font-size: 10px;
}

.order-card-avatar {
	background: url('../../../assets/media/img/background-order-img.png');
	background-size: contain;
	min-width: 40px;
	height: 40px;
	color: white;
	border-radius: 19px;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-right: 15px;
}

.order-card-subhead-information {
	display: flex;
	flex-direction: column;
}

.order-card-subhead-information-client {
	width: 214.7px;
	height: 24px;
	font-family: 'Roboto';
	font-size: 20.2px;
	font-weight: 500;
	font-style: normal;
	font-stretch: normal;
	line-height: normal;
	letter-spacing: 0.25px;
	color: #ffffff;
	overflow: overlay;
}

.order-card-subhead-information-client::-webkit-scrollbar {
	display: none;
}

.order-card-subhead-information-order {
	width: 100px;
	height: 23px;
	font-family: 'Eczar';
	font-size: 15.7px;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.53;
	letter-spacing: 0.1px;
	color: #ffffff;
	margin-right: 5px;
}

.order-card-body {
	display: flex;
	padding: 9px 0 0 18px;
}

.order-card-body-address {
	height: 40px;
	font-family: 'Roboto';
	font-size: 14.2px;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.41;
	letter-spacing: 0.25px;
	color: rgba(255, 255, 255, 0.6);
	overflow: overlay;
}

.order-card-body-address::-webkit-scrollbar {
	display: none;
}

.order-card-body-zone {
	color: #ffffff;
}

.order-card-footer {
	display: flex;
	align-items: center;
	padding: 5px 10px 0 0;
}

.order-card-footer-space-between {
	justify-content: space-between;
}

.order-card-footer-space-evenly {
	justify-content: space-evenly;
}

#order-card-footer-button {
	height: 36px;
	background-color: rgba(98, 2, 238, 0);
	margin-right: 5px;
	font-family: 'Roboto';
	font-size: 13px;
	font-weight: bold;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.13;
	letter-spacing: 1.25px;
	text-align: center;
	color: #1eb980;
}

#order-card-footer-button:active {
	opacity: 0.16;
	background-color: #6e6e7a;
	border-radius: 20px;
}

.order-card-footer-text {
	width: 100%;
	height: 16px;
	font-family: 'Roboto';
	font-size: 14.1px;
	font-weight: bold;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.13;
	letter-spacing: 1.25px;
	text-align: center;
	color: rgba(255, 255, 255, 0.38);
}

.order-card-footer-count {
	width: 24px;
	height: 16px;
	font-family: 'Roboto';
	font-size: 12.2px;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.31;
	letter-spacing: 2px;
	text-align: center;
	color: rgba(255, 255, 255, 0.6);
}

.btn-order-assign-user {
	width: 100%;
}

@media (min-width: 1025px) {
	.control-mobile-wrap-list-draggable {
		flex-direction: row;
	}
	.control-mobile-wrap-list-draggable-sort {
		align-items: center;
	}
}

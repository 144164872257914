.tabs-body-information-order {
	height: 73.6vh;
}

.tab-content-information-order {
	height: 65vh;
	padding: 10px 10px;
}

.section-information-order {
	margin-bottom: 10px;
}

.row-item {
	display: flex;
	justify-content: space-between;
}

.item-concept {
	font-size: 12px;
	margin-right: 5px;
	font-weight: normal;
	color: lightseagreen;
}

.item-value-information {
	font-size: 12px;
	font-weight: normal;
	cursor: pointer;
	color: lightgrey;
	text-align: right;
}

.item-value-information:hover {
	color: white;
	font-weight: bold;
}

.modal-getOrderInformation-billingReport-title-container {
	height: 28px;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.order_information-list-item-add-discount {
	font-family: Roboto;
	height: 30px;
	padding: 5px;
	display: flex;
	align-items: center;
	cursor: pointer;
	color: white;
}

.order_information-list-item-add-discount:hover {
	color: lightseagreen;
}

.order_information-list-item-add-discount:active {
	color: lightskyblue;
}

.order_information-list-item-add-discount-selected {
	background-color: #3f3f49;
}

.order_information-list-item-discounted {
	font-family: Roboto;
	height: 30px;
	padding: 5px;
	display: flex;
	align-items: center;
	color: burlywood;
}

.mainContainer {
	position: relative;
	width: 100%;
	height: 100%;
	color: whitesmoke;
}

.closeButtonWrapper {
	position: absolute;
	top: 0;
	right: 0;
	padding: 5px;
	z-index: 10;
}

.imageViewerWrapper {
	width: 70vw;
	height: 100%;
	float: left;
}

.imageViewerWrapperFullWidth {
	width: 100%;
}

.imgWrapper {
	height: calc(100vh - 80px);
}

.imgWrapper > img {
	height: 100vh;
}

.thumbnailWrapper * img {
	height: 62px;
	object-fit: contain;
}

.commentsBoxContainer {
	position: relative;

	color: whitesmoke;
	height: 100%;
	overflow-y: scroll;
}

.createdByWrapper {
	position: sticky;
	top: 0;
	z-index: 1;

	display: flex;
	width: 100%;
	padding: 10px 5px;
	justify-content: flex-start;
	border-bottom: 1px solid grey;
}

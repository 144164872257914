@import url('https://fonts.googleapis.com/css?family=Eczar|Roboto&display=swap');

/* Font */
.standardTextStructure {
	font-family: 'Roboto';
	font-style: normal;
	mix-blend-mode: normal;
}

.ellipsis {
	display: inline-block;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
}

.ellipsisMultylines {
	display: -webkit-box;
	-webkit-box-orient: vertical;
	overflow: hidden;
	-webkit-line-clamp: 2;
}
.webkitLineClamp_3 {
	-webkit-line-clamp: 3;
}
.webkitLineClamp_4 {
	-webkit-line-clamp: 4;
}
.webkitLineClamp_5 {
	-webkit-line-clamp: 5;
}

/* Events */
.cursorPointer {
	cursor: pointer;
}
.hoverable:hover {
	cursor: pointer;
}
.wordBreak {
	word-wrap: break-word;
}
.uppercase {
	text-transform: uppercase;
}
.textAlign_center {
	text-align: center;
}
.textAlign_right {
	text-align: right;
}
.textAlign_left {
	text-align: left;
}
.disabled {
	cursor: not-allowed;
}

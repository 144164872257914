.maincontainer-subpage {
	width: 100%;
	display: flex;
	justify-content: center;
	position: absolute;
}

.width-100-percent {
	width: 100%;
}

.div-container-list-orders {
	width: 100%;
	overflow: auto;
}

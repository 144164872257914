.actionsBox {
	display: inline-flex;
	width: 100%;
	padding: 3px 0;
}

.actionButtonsContainer {
	display: inline-flex;
	justify-content: flex-start;
	width: 50%;
	margin: 0 5px;
}

.reactionsContainer {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	margin: 0 5px;
	width: 50%;
}

.buttonContentWrapper {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
}

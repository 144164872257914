.stContainer {
	display: flex;
	justify-content: space-between;
	height: 48px;
	padding: 10px 0;
}

.buttonsContainer {
	display: flex;
	align-items: center;
}

.textDetailsContainer {
	composes: buttonsContainer;
	padding-left: 16px;
}

.wrapperTag {
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 12.2px;
	font-weight: normal;
	font-stretch: normal;
	line-height: 1.31;
	mix-blend-mode: 'normal';
	letter-spacing: 0.4px;
	padding: 3px;
	border-radius: 10px;
	margin: 0px;
	border: 1px solid #1eb980;
}

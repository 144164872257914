.payments-main-container {
	position: absolute;
	top: 12px;
	width: 100%;
}

.billings-cost_sale-container {
	width: 113px;
	height: 40px;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.billings-cost_sale-data {
	margin-right: 10px;
}

.billings-saleCostColor {
	width: 10px;
	height: 10px;
	border-radius: 10px;
}

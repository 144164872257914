.sign_paycut-container {
	height: auto;
	display: flex;
	flex-direction: column;
	background-color: #3f3f49;
}

.sign_paycut-form {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	margin: 10px;
}

.sign_paycut-button-sign-container {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: flex-end;
}

.sign_paycut-signatures-item-container {
	display: flex;
	align-items: center;
}

.sign_paycut-signatures-container {
	margin-bottom: 10px;
	padding: 10px;
	border-radius: 20px;
	background-color: #2c2c36;
	display: flex;
	flex-wrap: wrap;
}

.sign_paycut-signatures-user_id-created_at-container {
	display: flex;
	flex-direction: column;
	align-items: baseline;
	margin: 0 10px 10px 0;
}

.sign_paycut-signatures-user_id {
	margin: 0 10px;
}

.sign_paycut-signatures-created_at {
	margin: 0 10px;
	font-size: 12px;
	color: cadetblue;
	font-family: Roboto;
}

.sign_paycut-line-container {
	width: 95%;
	display: flex;
	justify-content: space-between;
}

.sign_paycut-line-concept {
	font-family: Roboto;
	color: lightblue;
}

.sign_paycut-line-value {
	font-family: Roboto;
	color: lightgray;
}

.sign_paycut-line-concept-total {
	font-family: Roboto;
	color: burlywood;
}

.sign_paycut-line-value-total {
	font-family: Roboto;
	color: burlywood;
}

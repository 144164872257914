#transaction_meta-card-container {
	padding: 10px;
	background: #3f3f49;
	height: 197px;
}

.transaction-container-data-input {
	display: flex;
	justify-content: space-between;
	margin: 0;
}

.transaction-data {
	width: 50%;
	display: flex;
	flex-direction: column;
	margin: 0;
	padding: 0 0 10px 0;
}

.transaction-container-title-card {
	display: flex;
	flex-direction: column;
	margin-bottom: 15px;
}

.transaction-body-container-item {
	display: flex;
	flex-wrap: wrap;
	width: 73vw;
}

.transaction-body-concept-item {
	font-size: 12px;
	font-family: 'Roboto';
	color: lightseagreen;
	margin-right: 10px;
}

.transaction-card-document {
	font-size: 13px;
	font-family: 'Roboto';
	color: white;
}

.transaction-card-creator {
	font-size: 12px;
	font-family: 'Roboto';
	color: lightgrey;
}

.transaction-card-creator-clickeable {
	font-size: 13px;
	font-family: 'Roboto';
	color: white;
}

.transaction-card-creator-clickeable:active {
	color: lightskyblue;
}

.transaction-card-created_at {
	font-family: 'Roboto';
	font-size: 12px;
	color: darkgray;
}

.transaction-buttons-container {
	display: flex;
	width: 100%;
	justify-content: space-around;
}

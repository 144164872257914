.paycuts-table-container {
	position: fixed;
	width: 100%;
}

.paycuts-table-header {
	background-color: rgb(63, 63, 73);
	border-radius: 1px;
	font-family: Roboto;
	font-size: 11px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.41;
	letter-spacing: 0.25px;
	color: rgba(255, 255, 255, 0.6);
	text-transform: capitalize;
}

.paycuts-table {
	background-color: rgb(63, 63, 73);
	border-radius: 1px;
}

.paycuts-cell-container-amounts {
	display: flex;
	align-items: center;
	height: 40px;
}

.paycuts-table-row {
	display: flex;
	align-items: center;
	cursor: pointer;
	border-bottom: 0.4px rgba(224, 224, 225, 0.7) solid;
	font-size: 11px;
	font-family: Roboto;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.41;
	letter-spacing: 0.25px;
	color: rgba(255, 255, 255, 0.6);
}

.paycuts-table-row:hover {
	font-size: 12px;
	font-weight: bold;
}

.paycuts-table-row:active {
	font-size: 12px;
	font-weight: bold;
}

.paycut-table-cell-name-container {
	height: 40px;
	display: flex;
	align-items: center;
	color: rgba(255, 255, 255, 0.6);
}

.paycut-table-cell-name-container:hover {
	color: white;
}

.contentWrapper {
	position: relative;
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-auto-rows: 1fr;

	width: 100%;
	height: 100%;
}

.imgWrapper {
	overflow: hidden;
	position: relative;
	width: 100%;
	height: 100%;
}

.contentWrapper > :nth-child(3):last-child {
	grid-column: 1 / 3;
	grid-row: 1 / 2;
}

.imgWrapper:only-child {
	grid-column: 1 / 3;
	grid-row: 1 / 3;

	width: 100%;
	height: 100%;
}

.imgWrapperList {
	composes: imgWrapper;
	height: 300px;
}

@media screen and (max-width: 600px) {
	.imgWrapperList {
		height: 200px;
	}
}

.imgWrapperGrid {
	composes: imgWrapper;
}

.imgWrapperCard {
	composes: imgWrapper;
	height: 71px;
}

.fullImageHeightCardView {
	height: 142px;
}

.preview {
	object-fit: cover;
	vertical-align: middle;
	width: 100%;
	height: 100%;
}

.imgMask {
	height: 100%;
	width: 100%;
	position: absolute;
	top: 0;
	display: grid;
	place-items: center;
}

.imgMask:hover {
	cursor: pointer;
}

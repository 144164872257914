.commentsBoxContainer {
	width: 100%;
	padding: 5px 15px;
}

.addReplyAvatarWrapper {
	display: flex;
	place-content: start flex-start;
	height: 32px;
}

.loadingWrapper {
	display: flex;
	width: 100%;
	place-content: center;
	padding: 5px 0;
}

.viewMoreContainer {
	padding-left: 10px;
}

.viewMoreLink {
	font-weight: bold;
	font-size: 12px;
}

.viewMoreLink:hover {
	color: #ccc;

	text-decoration: underline;
}

.commentAction {
	composes: viewMoreLink;
	font-size: 11px;
}

.viewMoreLinkLarge {
	composes: viewMoreLink;
	color: #0aac66;
	font-size: 14px;
}

.viewMoreLinkLarge:hover {
	color: #0aac66;
}

.create-article-container {
	margin: 0 10px 10px 10px;
	padding-bottom: 10px;
	height: auto;
	display: flex;
	flex-direction: column;
	background-color: #3f3f49;
}

.create-article-form-items {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin: 10px;
}

.create-article-form-items-select {
	width: 260px;
}

.create-article-form-checkboxes {
	margin: 5px 0 15px 0;
	height: 60px;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	padding-left: 60px;
	align-items: center;
}

.create-article-form-action-button {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-around;
}

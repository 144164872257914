#billingMaterialCard {
	padding: 10px;
	-webkit-box-shadow: 2px 5px 17px 2px rgba(0, 0, 0, 0.75);
	-moz-box-shadow: 2px 5px 17px 2px rgba(0, 0, 0, 0.75);
	box-shadow: 2px 5px 17px 2px rgba(0, 0, 0, 0.75);
	margin-bottom: 8px;
}

.billing_mat-container-description-card {
	height: 6vh;
	display: flex;
	align-items: baseline;
}

.billing_mat-container-data {
	width: 50%;
	color: lightgray;
}

.billing_mat-container-reject {
	width: 355px;
}

.billing_mat-disapprove-inventory-item {
	font-family: Roboto;
	font-size: 12.2px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.31;
	letter-spacing: 0.4px;
	color: #1eb980;
	border: 1px solid #1eb980;
	padding: 3px;
	border-radius: 10px;
}

.billing_mat-disapprove-inventory-item:active {
	color: white;
	border: 1px solid white;
}

.billing_mat-disapproved-inventory-item {
	font-family: Roboto;
	font-size: 12.2px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.31;
	letter-spacing: 0.4px;
	color: darkgrey;
	border: 1px solid darkgrey;
	padding: 3px;
	border-radius: 10px;
}

.billing_mat-disapproved-inventory-item:active {
	color: white;
	border: 1px solid white;
}

.billing_mat-container-title-card {
	max-width: 500px;
	justify-content: space-between;
	display: flex;
	align-items: center;
}

.billing_mat-container-title-card-data {
	display: flex;
	flex-direction: column;
	margin-bottom: 5px;
}

.billing_mat-container-title-card-reject-button {
	cursor: pointer;
	margin-right: 15px;
}

#payment_line-resume-card-container {
	height: 130px;
	padding: 10px;
	background: rgba(204, 186, 186, 0.08);
}

.payment_line-resume-flag-payment-state {
	margin-top: 5px;
	min-width: 5px;
	height: 30px;
	position: fixed;
}

.payment_line-resume-body {
	display: flex;
	flex-direction: column;
}

.payment_line-resume-title {
	display: flex;
	justify-content: space-between;
}

.payment_line-resume-title-line-creator-container {
	width: 75%;
	display: flex;
	flex-direction: column;
}

.payment_line-resume-title-creator {
	font-size: 12px;
	font-family: 'Roboto';
	color: silver;
}

.payment_line-resume-title-line {
	font-size: 13px;
	font-family: 'Roboto';
	color: white;
}

.payment_line-resume-title-line:active {
	color: lightgrey;
}

.payment_line-resume-price-action-container {
	display: flex;
	flex-direction: column;
	align-items: flex-end;
}

.payment_line-resume-price {
	font-size: 12px;
	font-family: 'Roboto';
	color: lightgrey;
}

.payment_line-resume-action {
	font-size: 12px;
	font-family: 'Roboto';
	color: lightgrey;
}

.payment_line-resume-footer {
	display: flex;
	justify-content: space-between;
	align-items: flex-end;
}

.payment_line-resume-concept-item {
	font-size: 12px;
	font-family: 'Roboto';
	color: burlywood;
	margin-right: 10px;
}

.payment_line-resume-concept-value {
	font-size: 12px;
	font-family: 'Roboto';
	color: lightgrey;
}

.payment_line-resume-order {
	font-size: 12px;
	font-family: 'Roboto';
	color: lightgrey;
}

.payment_line-resume-order:active {
	color: white;
}

.create-user-container {
	width: 100%;
	margin-top: 12px;
	display: flex;
	flex-direction: row;
	justify-content: center;
}

.create-user-form {
	display: flex;
	flex-direction: column;
}

.input {
	margin-top: 5px !important;
	width: 200px !important;
}

.main-input {
	margin-bottom: 20px !important;
}

#input-table {
	width: 100%;
}

.item-center {
	justify-content: center;
}

.title-set-user-configuration-modal-container {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding-right: 20px;
}

.waterMark {
	overflow: hidden;
	box-sizing: border-box;
	width: 100%;
	height: 30%;
	padding: 5px;
	position: absolute;
	left: 0;
	bottom: 0;
	display: flex;
	justify-content: space-around;
	align-items: flex-start;
	flex-wrap: wrap;
	font-weight: bold;
}
.containerImg {
	overflow: hidden;
	box-sizing: border-box;
	height: fit-content;
	width: 45%;
	max-width: 80px;
	padding: 5px;
	border-radius: 3px;
	position: absolute;
	top: 0;
	left: 0;
	display: flex;
	justify-content: center;
	align-items: center;
}

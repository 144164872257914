.warehouses-main-container {
	width: 100%;
	display: flex;
	justify-content: center;
	position: absolute;
	top: 12px;
}

.imported-mode-content-container {
	margin-top: 15px;
	display: flex;
	justify-content: space-between;
}

.div-imported-format {
	margin-right: 10px;
	width: 50%;
	color: gray;
	font-size: 12px;
}

.div-buttons-container {
	display: flex;
	align-items: center;
}

#button-do-transaction {
	margin-top: 10px;
}

.show-bugs-container {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	width: 100%;
}

.content-bugs-container {
	display: flex;
	flex-direction: column;
	margin: 0 5px 0 5px;
}

.title-bug-area {
	margin-top: 3px;
	margin-bottom: 3px;
	text-align: center;
	padding: 10px;
	background-color: cyan;
}

.list-item-bug {
	padding: 10px;
	list-style: none;
}

.list-item-bug:nth-child(odd) {
	background-color: mintcream;
}

.list-item-bug:nth-child(even) {
	background-color: aliceblue;
}

.subcontainer-warehouses {
	margin-top: 100px;
	display: flex;
	justify-content: space-around;
	flex-direction: row;
	flex-wrap: wrap;
}

.item-warehouse {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.intermodule-warehouse {
	display: flex;
	flex-direction: row;
	justify-content: center;
}

.container-list-items-main-modal-warehouses {
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	align-items: normal;
}

.container-list-items-main-modal-transfer {
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	align-items: center;
}

.list-item-div {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.margin-item {
	margin-left: 10px;
}

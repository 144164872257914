@value sharedStyles: "../../themes/sharedStyles.module.css";

.subcaption {
	composes: standardTextStructure from sharedStyles;
	font-weight: 500;
	font-size: 10px;
	line-height: 16px;
	letter-spacing: 1.5px;
	text-transform: uppercase;
}

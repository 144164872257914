.container-signup-form {
	display: flex;
	flex-direction: column;
	max-width: 800px;
}

#container-signup-form-items {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: center;
}

.container-signup-form-action-buttons {
	max-width: 260px;
	height: 80px;
	margin-bottom: 10px;
	display: flex;
	justify-content: space-between;
	flex-direction: column;
	align-items: center;
}

.container-signup-form-button {
	width: 134px;
	margin-top: 10px;
	text-align: center;
}

#container-signup-form-selects {
	width: 200px;
	margin-bottom: 10px;
}

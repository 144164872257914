#order_history_card-meta {
	padding: 10px;
	-webkit-box-shadow: 2px 5px 17px 2px rgba(0, 0, 0, 0.75);
	-moz-box-shadow: 2px 5px 17px 2px rgba(0, 0, 0, 0.75);
	box-shadow: 2px 5px 17px 2px rgba(0, 0, 0, 0.75);
	margin-bottom: 8px;
}

.order_history-container-description-card {
	display: flex;
	flex-direction: column;
	margin: 0;
	max-width: 450px;
}

.order_history-card-container-item {
	display: flex;
	flex-wrap: wrap;
	padding-right: 5px;
}

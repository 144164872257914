/* Container */
.postWrapper {
	position: relative;
	width: 440px;
	height: 541px;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	overflow: hidden;
	box-shadow:
		0px 2px 4px rgba(7, 6, 6, 0.14),
		0px 3px 4px rgba(0, 0, 0, 0.12),
		0px 1px 5px rgba(0, 0, 0, 0.2);
}

.titleAndMoreIcon {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	padding-bottom: 5px;
}

.titleAndMoreIcon div {
	margin-left: auto;
	display: inline-flex;
}

/* ProgressBar */

.userNameAndReviewProgress {
	display: flex;
	flex-direction: row;
	width: 100%;
	gap: 5px;
}

.createdBy {
	display: flex;
	justify-content: flex-start;
	align-items: stretch;
	padding: 0 5px;
	height: 40px;
}

.infoWrapper {
	display: grid;
	grid-template-columns: 1fr;
	min-height: 100%;
	align-items: center;
	padding-left: 10px;
}

.userName {
	display: block;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
}

.pubDate {
	display: block;
	font-size: 12px;
	letter-spacing: 0.4px;
}

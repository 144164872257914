@import '~react-image-gallery/styles/css/image-gallery.css';
@import url('https://fonts.googleapis.com/css?family=Eczar|Roboto&display=swap');
.Roboto {
	font-family: Roboto;
}

.clickeable {
	cursor: pointer;
}

.clickeable:hover {
	color: lightseagreen;
}

.clickeable:active {
	color: lightseagreen;
}

.dashboard {
	background-color: rgb(37, 37, 38);
	background-size: cover;
	background-repeat: no-repeat;
	width: 300px;
	height: 100%;
	display: flex;
	flex-direction: column;
	font-family: 'Roboto';
}

.under_building {
	height: 700px;
	width: 100%;
	background-image: url('./assets/media/img/under_building-min.jpg');
	background-size: contain;
	background-repeat: no-repeat;
}

.under_building_mini {
	height: 200px;
	width: 100%;
	background-image: url('./assets/media/img/under_building-min.jpg');
	background-size: contain;
	background-repeat: no-repeat;
}

.style-toolbar-buttons {
	color: rgba(255, 255, 255, 0.54);
}

.standard-toolbar-buttons:hover {
	color: white;
}

.page__content {
	overflow-x: hidden;
}

.scrollable {
	overflow: auto;
}

.scrollable::-webkit-scrollbar {
	display: none;
}

/* GLOBALS STYLES */

/*********************TABLE VIRTUALIZED******************************/
.ReactVirtualized__Table__headerColumn:first-of-type,
.ReactVirtualized__Table__rowColumn:first-of-type {
	margin-left: 5px;
}
/*********************ANT TABS******************************/

.ant-tabs-tab {
	color: white; /*TODO: Ya esta configurado el Componente Tabs... Borrar esta línea cuando termine de migrar todas las Tabs y TabPane*/
}

/*********************ANT CARD****************************/
.ant-card-body {
	background-color: transparent;
	padding: 0;
}

.ant-card-meta-detail {
	width: 100%;
}

.ant-card-meta-detail > div:not(:last-child) {
	margin-bottom: 0;
}

.ant-card-bordered {
	border: none;
}

/*********************ANT MODALS****************************/
.modal-title {
	font-family: Roboto;
	font-size: 16.2px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.48;
	letter-spacing: 0.15px;
	color: white;
}

/*********************ANT EMPTY*****************************/
.ant-empty-description {
	color: white;
}
.ant-empty-normal {
	margin: 0;
}

.ant-empty-normal .ant-empty-image {
	height: 25px;
}

/*********************ANT CALENDAR PICKER INPUT***********************/
.ant-calendar-picker-clear {
	background-color: transparent;
	color: rgba(255, 255, 255, 0.6);
}

.ant-calendar-picker-clear:hover {
	background-color: transparent;
	color: rgba(255, 255, 255, 0.6);
}

.ant-calendar-picker-icon {
	color: rgba(255, 255, 255, 0.6);
}

/*********************ANT TIME PICKER INPUT***********************/
.ant-time-picker-input {
	background-color: rgba(204, 186, 186, 0.08);
	color: rgba(255, 255, 255, 0.6);
}

.ant-time-picker-clear {
	background-color: transparent;
	color: rgba(255, 255, 255, 0.6);
}

.ant-time-picker-clear:hover {
	background-color: transparent;
	color: rgba(255, 255, 255, 0.6);
}

.ant-time-picker-icon .ant-time-picker-clock-icon {
	color: rgba(255, 255, 255, 0.6);
}

/*********************ANT CHECKBOX************************/
.ant-checkbox-inner {
	background-color: transparent;
	/* border-color: #2c2c36; */
	border-color: rgba(255, 255, 255, 0.3);
}

.ant-checkbox-wrapper + .ant-checkbox-wrapper {
	margin: 0 !important;
}

.ant-checkbox-checked .ant-checkbox-inner {
	background-color: rgb(0, 139, 82);
	color: rgb(59, 68, 74);
}

/*********************ANT COLLAPSE************************/
.ant-collapse-borderless {
	background-color: transparent;
	border: none;
}

.ant-collapse-content > .ant-collapse-content-box {
	padding: 0;
}

.ant-collapse-arrow {
	color: rgba(255, 255, 255, 0.6) !important;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header {
	align-items: baseline;
}

/*********************ANT SPIN************************/
.ant-spin-text {
	color: white;
	font-family: Roboto;
}
.ant-spin-blur {
	border-radius: 20px;
}

/*********************ANT NOTIFICATION************************/
.ant-notification {
	margin-right: 10px !important;
}

.ant-notification-notice {
	margin-bottom: 10px !important;
}
.ant-notification-notice-close {
	color: white !important;
}

.ant-notification-notice-message {
	color: #ececf9 !important;
}

.ant-notification-notice-description {
	font-family: Roboto !important;
	font-size: 13px !important;
	font-weight: normal !important;
	font-style: normal !important;
	font-stretch: normal !important;
	line-height: 1.41 !important;
	letter-spacing: 0.25px !important;
}

/*********************ANT RADIO BUTTON************************/
.ant-radio-button-wrapper {
	color: white;
	background-color: transparent !important;
}

.ant-radio-button-wrapper-checked {
	color: #40a9ff;
}

/*********************ANT BREADCRUMB************************/
.ant-breadcrumb {
	color: rgba(255, 255, 255, 0.6);
}

.ant-breadcrumb-separator {
	color: rgba(255, 255, 255, 0.6);
}

/*********************ANT INPUT************************/
.ant-input-affix-wrapper {
	background-color: transparent;
	border: none;
}

/*********************BUTTON*****************************/
.button-style-1 {
	height: 36px;
	font-family: Roboto;
	font-size: 14.1px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.13;
	letter-spacing: 1.25px;
	text-align: center;
	background-color: #1eb980;
	color: rgba(0, 0, 0, 0.87);
}

/*********************VARIOUS*****************************/
.form-item-standard {
	margin-bottom: 10px;
	max-width: 260px;
}

.ant-switch-checked {
	background-color: #169981;
}

.ant-input {
	background-color: transparent;
	color: #a5a5a5;
}

.ant-input-group-addon {
	background-color: transparent;
	color: #a5a5a5;
}
/************************************************************/

.txt-white {
	color: white;
}

.item-none-display {
	display: none;
}

.ant-dropdown-menu {
	background-color: #1f1b24;
}

.ant-dropdown-menu-title-content {
	color: #fff;
}
.ant-dropdown-menu-submenu-title:hover {
	background-color: #ffffff1f;
}

.ant-dropdown-menu-submenu-title {
	display: flex;
}
.ant-dropdown-menu-submenu-title
	.ant-dropdown-menu-submenu-expand-icon
	.ant-dropdown-menu-submenu-arrow-icon {
	color: white;
}

.ant-dropdown-menu-item-selected,
.ant-dropdown-menu-submenu-title-selected {
	background-color: #ffffff1f;
}

.ant-input-affix-wrapper {
	background-color: #1f1b24;
}

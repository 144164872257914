#order_resume-card-container {
	height: 80px;
	padding: 10px;
}

.order_resume-container-title-card {
	display: flex;
	flex-direction: column;
	margin-bottom: 15px;
}

.order_resume-card-order-price-container {
	width: 100%;
	display: flex;
	justify-content: space-between;
}

.order_resume-card-order {
	font-size: 13px;
	font-family: 'Roboto';
	color: white;
	cursor: pointer;
}

.order_resume-card-order:hover {
	color: lightgrey;
}

.order_resume-card-order:active {
	color: lightgrey;
}

.order_resume-card-task {
	font-size: 13px;
	font-family: 'Roboto';
	color: white;
	overflow-x: auto;
	overflow-y: hidden;
}

.order_resume-card-price {
	font-size: 12px;
	font-family: 'Roboto';
	color: lightgrey;
}

.order_resume-body-concept-item {
	font-size: 12px;
	font-family: 'Roboto';
	color: burlywood;
	margin-right: 10px;
}

.order_resume-card-creator {
	font-size: 12px;
	font-family: 'Roboto';
	color: lightgrey;
}

.order_resume-card-created_at {
	font-family: 'Roboto';
	font-size: 12px;
	color: lightgrey;
}

.order_resume-flag-payment-state {
	margin-top: 5px;
	min-width: 5px;
	height: 30px;
	position: fixed;
}

.order_resume-footer {
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: baseline;
}

.order_resume-counter {
	font-size: 12px;
	font-family: Roboto;
	color: lightgrey;
}

.commentModal {
	width: 100%;
	height: 100%;
	color: whitesmoke;

	overflow-y: scroll;
	max-height: 80vh;
}

.commentModalMobile {
	composes: commentModal;
	max-height: 100vh;
}

.closeButtonWrapper {
	position: absolute;
	right: 0;
	top: 0;
	padding: 5px;
	z-index: 10;
}

.createdByWrapper {
	position: sticky;
	top: 0;
	z-index: 1;
	background-color: #242526;

	width: 100%;
	padding: 10px 5px;
	border-bottom: 1px solid grey;
}

.collectorName {
	font-weight: 550;
	font-size: 16px;
	padding-left: 10px;
}

.valueWrapper {
	padding: 10px;
}

.bottom {
	width: 100%;
}

#inventory_transaction-meta {
	padding: 10px;
}

.inventory_transaction-container-data-input {
	display: flex;
	justify-content: space-between;
	margin: 0;
	padding: 0 0 10px 0;
}

.inventory_transaction-data {
	width: 70%;
	display: flex;
	flex-direction: column;
	margin: 0;
	padding: 0 0 10px 0;
}

.inventory_transaction-button {
	width: 30%;
	display: flex;
	flex-direction: column;
	margin: 0;
	padding: 0 0 10px 0;
}

.inventory_transaction-container-title-card {
	display: flex;
	flex-direction: column;
	margin-bottom: 5px;
}

.inventory_transaction-body-container-item {
	display: flex;
	flex-wrap: wrap;
	width: 73vw;
}

.inventory_transaction-body-concept-item {
	width: 80%;
	font-size: 12px;
	font-family: 'Roboto';
	color: lightseagreen;
	margin-right: 10px;
}

.inventory_transaction-card-creator {
	font-size: 13px;
	font-family: 'Roboto';
	color: white;
	font-weight: bold;
}

.inventory_transaction-card-creator-clickeable {
	font-size: 13px;
	font-family: 'Roboto';
	color: white;
}

.inventory_transaction-card-creator-clickeable:active {
	color: lightskyblue;
}

.inventory_transaction-card-created_at {
	font-family: 'Roboto';
	font-size: 12px;
	color: darkgray;
}

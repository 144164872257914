#paycut_meta-card-container {
	padding: 10px;
	background: #3f3f49;
	height: 210px;
}

.paycut-container-data-input {
	display: flex;
	justify-content: space-between;
	margin-bottom: 5px;
}

.paycut-data {
	width: 60%;
	display: flex;
	flex-direction: column;
	margin: 0;
	padding: 0 0 10px 0;
}

.paycut-container-title-card {
	display: flex;
	flex-direction: column;
	margin-bottom: 15px;
}

.paycut-body-container-item {
	width: 73vw;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
}

.paycut-body-concept-item {
	font-size: 12px;
	font-family: 'Roboto';
	color: silver;
	margin-right: 10px;
}

.paycut-card-document {
	font-size: 13px;
	font-family: 'Roboto';
	color: white;
}

.paycut-card-creator {
	font-size: 12px;
	font-family: 'Roboto';
	color: lightgrey;
}

.paycut-card-creator-clickeable {
	font-size: 13px;
	font-family: 'Roboto';
	color: white;
}

.paycut-card-creator-clickeable:active {
	color: lightskyblue;
}

.paycut-card-created_at {
	font-family: 'Roboto';
	font-size: 12px;
	color: darkgray;
}

.paycut-buttons-container {
	display: flex;
	width: 100%;
	justify-content: space-between;
	align-items: center;
}

.paycut-flag-payment-state {
	margin-top: 5px;
	min-width: 5px;
	height: 40px;
	position: fixed;
}

.switch {
	height: 0;
	width: 0;
	visibility: hidden;
}
.point {
	cursor: pointer;
	text-indent: -9999px;
	width: 20px;
	height: 10px;
	display: block;
	border-radius: 100px;
	position: relative;
}

.point:after {
	content: '';
	position: absolute;
	top: 1px;
	left: 1px;
	width: 8px;
	height: 8px;
	background: #fff;
	border-radius: 90px;
	transition: 0.3s;
}

input:checked + .point {
	/* background: #bada55; */
	/* background: yellow; */
}

input:checked + .point:after {
	left: calc(100% - 1px);
	transform: translateX(-100%);
}

.point:active:after {
	width: 8px;
	background-color: black;
}

.control-desktop-title-expert {
	margin-right: 15px;
	color: rgba(255, 255, 255, 0.9);
	font-family: 'Roboto';
}

.icon-btn-save {
	margin-left: 15px !important;
	visibility: visible;
}
.icon-btn-save-hidden {
	margin-left: 15px !important;
	visibility: hidden;
}

.main-content-table {
	width: 100%;
}

.control-personalize-area {
	display: flex;
}

.container-control-search-orders {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.MuiFormGroup-root-209 {
	display: flex;
	flex-direction: row !important;
}

.circular-progress-assign-order {
	display: flex;
	justify-content: center;
}

.modal-update-event-orders {
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	align-items: center;
}

.search-orders-button-control {
	display: flex;
	align-items: baseline;
}

.button-search {
	margin-right: 5px;
}

.table-cell {
	width: 129px;
	height: 20px;
	font-family: Roboto;
	font-size: 11px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.41;
	letter-spacing: 0.25px;
	color: #ffffff;
	text-transform: lowercase;
}

.table {
	background-color: rgb(63, 63, 73);
	border-radius: 1px;
}

.table-index-cell-container {
	display: flex;
	align-items: center;
}

.ReactVirtualized__Table__headerRow {
	border-bottom: 0.6px rgba(224, 224, 225, 0.7) solid !important;
}

.table-row-container {
	display: flex;
	align-items: center;
}

.flag-priority {
	min-width: 5px;
	height: 20px;
	margin-right: 10px;
}

.table-row {
	display: flex;
	align-items: center;
	cursor: pointer;
	border-bottom: 0.4px rgba(224, 224, 225, 0.7) solid;
	font-size: 13px;
	font-family: Roboto;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.41;
	letter-spacing: 0.25px;
	color: rgba(255, 255, 255, 0.6);
}

.table-row:hover {
	font-size: 13px;
	font-weight: bold;
}

.table-row:active {
	font-size: 13px;
	font-weight: bold;
}

.table-header {
	border-radius: 1px;
	font-family: Roboto;
	font-size: 11px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.41;
	letter-spacing: 0.25px;
	color: rgba(255, 255, 255, 0.6);
	text-transform: capitalize;
}

.table-order-header {
	cursor: pointer;
}

.table-order-header:hover {
	color: white;
}

.table-order-cell {
	cursor: pointer;
}

.table-order-cell:hover {
	color: white;
}

.tabs-container-popover-details-order {
	height: 560px;
}

.container-empty {
	height: 500px;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.overflow {
	overflow: auto;
}

.section-information-order {
	margin-bottom: 10px;
}

.row-item {
	display: flex;
	justify-content: space-between;
}

.item-concept {
	font-size: 12px;
	margin-right: 5px;
	font-weight: normal;
	color: lightseagreen;
}

.item-value-information {
	font-weight: normal;
	cursor: pointer;
	font-size: 12px;
	color: lightgrey;
	text-align: right;
}

.item-value-information:hover {
	color: lightseagreen;
}

.subtitle-item {
	font-size: 13px;
	margin-bottom: 8px;
	font-weight: normal;
	color: aliceblue;
}

.container-title-popover {
	display: flex;
	justify-content: space-between;
}

.title-history-orders-income {
	cursor: pointer;
	display: flex;
	width: 70px;
}

.title-history-orders-income:hover {
	color: lightseagreen;
}

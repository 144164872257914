#warehouseCard-meta {
	padding: 10px;
}

.warehouseCard-container-description {
	display: flex;
	justify-content: space-between;
}

.warehouseCard-container-title {
	max-width: 500px;
	display: flex;
	margin-bottom: 15px;
}

.warehouseCard-container-title-data {
	display: flex;
	flex-direction: column;
	margin-bottom: 5px;
}

.warehouseCard-name {
	font-size: 13px;
	font-family: 'Roboto';
	color: white;
}

.warehouseCard-name:active {
	color: darkgray;
}

.warehouseCard-owner {
	font-family: 'Roboto';
	font-size: 12px;
	color: darkgray;
}

.warehouseCard-creator {
	font-family: 'Roboto';
	font-size: 11.5px;
	color: darkgray;
}

.warehouseCard-created_at {
	font-family: 'Roboto';
	font-size: 11.5px;
	color: darkgray;
}

.container {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	width: 100%;
	height: 100%;
	min-height: 25px;
}

.postWrapper {
	position: relative;
	display: flex;
	justify-content: space-between;
	align-items: center;
	min-height: 40px;
	width: 100%;
	padding: 0px 15px;
	background-color: transparent;
}
.postWrapper:hover {
	cursor: pointer;
	text-decoration: none;
}

.sectionIcons {
	display: flex;
	width: 50%;
	align-items: center;
	justify-content: flex-start;
}

.actionButtons {
	display: flex;
	width: 50%;
	align-items: center;
	justify-content: flex-end;
}

.dateWrapper {
	display: flex;
	height: 100%;
	place-items: center;
}

.iconWrapper {
	margin-left: 10px;
	display: flex;
	place-content: center;
	width: 40px;
	height: 100%;
}

.serie_history-flag {
	width: 4px;
	height: 40px;
	margin-top: 4px;
	margin-right: 7px;
}

.serie_history-flag-completed {
	background-color: white;
}

.serie_history-flag-pending {
	background-color: orange;
}

.serie_history-container-description-card {
	display: flex;
	align-items: baseline;
}

.serie_history-container-data {
	width: 50%;
	color: lightgray;
}

.serie_history-container-reject {
	width: 355px;
}

.serie_history-disapprove-inventory-item {
	font-family: Roboto;
	font-size: 12.2px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.31;
	letter-spacing: 0.4px;
	color: #1eb980;
	border: 1px solid #1eb980;
	padding: 3px;
	border-radius: 10px;
}

.serie_history-disapprove-inventory-item:active {
	color: white;
	border: 1px solid white;
}

.serie_history-disapproved-inventory-item {
	font-family: Roboto;
	font-size: 12.2px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.31;
	letter-spacing: 0.4px;
	color: darkgrey;
	border: 1px solid darkgrey;
	padding: 3px;
	border-radius: 10px;
}

.serie_history-disapproved-inventory-item:active {
	color: white;
	border: 1px solid white;
}

.serie_history-container-title-card {
	max-width: 500px;
	display: flex;
}

.serie_history-container-title-card-data {
	display: flex;
	flex-direction: column;
	margin-bottom: 5px;
}

.serie_history-container-title-card-reject-button {
	cursor: pointer;
	margin-right: 15px;
}

.create_line-form-select-item {
	display: flex;
	flex-direction: column;
}

.create_line-item-default {
	margin: 1.5px 0;
	padding: 0 20px;
	display: flex;
	justify-content: space-between;
	font-family: Roboto;
	color: burlywood;
}

.create_line-head {
	width: 92%;
	height: 28px;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

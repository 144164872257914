@value sharedStyles: "../../themes/sharedStyles.module.css";

.button {
	composes: standardTextStructure from sharedStyles;
	margin: 0;
	height: 16px;
	padding: 0;
	border: 1px solid transparent;
	align-items: center;
	cursor: pointer;
	text-align: center;
}

/* Foreground */
@value _primaryColor: #37474F;
@value _mediumEmphasisColor: #00000099;
@value _highEmphasisColor: #000000DE;
@value _secondaryColor: #007A46;
@value _inactiveColor: #0000008A;
@value _warningColor: #FF0000;
/* Background */
@value _primaryBackgroundColor: #FFFFFF;
@value _secondaryBackgroundColor: #FAFAFA;
@value _surfaceBackgroundColor: #ECEFF1;
@value _transparentBackgroundColor: transparent;
@value _disabledBackgroundColor: #2E7D323D;
/* Border */
@value _borderColor: #2E7D323D;

/* Foreground */
.primaryColor {
	color: _primaryColor;
}
.mediumEmphasisColor {
	color: _mediumEmphasisColor;
}
.highEmphasisColor {
	color: _highEmphasisColor;
}
.secondaryColor {
	color: _secondaryColor;
}
.inactiveColor {
	color: _inactiveColor;
}
.warningColor {
	color: _warningColor;
}

/* Background */
.primaryBackgroundColor {
	background-color: _primaryBackgroundColor;
}
.secondaryBackgroundColor {
	background-color: _secondaryBackgroundColor;
}
.surfaceBackgroundColor {
	background-color: _surfaceBackgroundColor;
}
.disabledBackgroundColor {
	background-color: _disabledBackgroundColor;
}
.transparentBackgroundColor {
	background-color: _transparentBackgroundColor;
}

/* Border */
.borderColor {
	border-color: _borderColor;
}

/* Events */

.hoverable:hover {
	color: _secondaryColor;
}
.focus:focus {
	color: _secondaryColor;
}
.required:after {
	content: ' *';
}
.required {
	composes: warningColor;
}

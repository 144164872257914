.subpages-container {
	background: #ffffff;
}

.toolbar-icon-slide-menu {
	color: #ffffff;
}

.font-raleway {
	font-family: Roboto;
}

.link-slidemenu-item {
	width: 100%;
	text-decoration: none;
}

.personalize-align-items {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	align-items: center;
}

.personalize-align-items-right {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
}

.dashboard-synapse-adm {
	position: relative;
	top: -10px;
	font-family: Roboto;
	font-size: 9px;
	color: rgba(255, 255, 255, 0.8);
}

.time-worked {
	color: cyan;
	margin-right: 30px;
	font-size: 15px;
	font-family: 'Roboto';
	position: relative;
	bottom: 5px;
}

/*NEW DESIGN*/

.version {
	width: 80px;
	height: 16px;
	font-family: Roboto;
	font-size: 12.2px;
	font-weight: bold;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.31;
	letter-spacing: 0.4px;
	text-align: right;
	margin-right: 8px;
	color: rgba(255, 255, 255, 0.8);
}

.Rectangle-13 {
	width: 360px;
	height: 24px;
	background-color: #2c2c36;
}

.dashboard-synapse-information {
	display: flex;
	margin-bottom: 30px;
}

.logo-version-div {
	display: flex;
	justify-content: space-between;
	padding-right: 35px;
}

.logo-synapse-app {
	width: 45px;
	height: 45px;
	background: url('../../assets/media/img/logo-synapse.svg');
	background-size: contain;
	margin-right: 5px;
	background-repeat: no-repeat;
}

.dashboard-synapse-name-company {
	width: 160px;
	height: 53px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.dashboard-synapse-name {
	width: 133px;
	height: 28px;
	font-family: 'Roboto';
	font-size: 24.3px;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: normal;
	letter-spacing: normal;
	color: #ffffff;
	display: block;
}

.dashboard-company-name {
	width: 208px;
	height: 20px;
	margin-left: 5px;
	font-family: 'Roboto';
	font-size: 14.2px;
	font-weight: bold;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.41;
	letter-spacing: 0.25px;
	color: #ffffff;
}

.dashboard-user-information-container {
	display: flex;
	justify-content: space-between;
}

.dashboard-user-information-subcontainer {
	display: flex;
}

.dashboard-user-information {
	display: flex;
	flex-direction: column;
	padding-left: 10px;
}

.dashboard-user-name {
	height: 20px;
	font-family: 'Roboto';
	font-size: 14.2px;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.41;
	letter-spacing: 0.25px;
	color: #ffffff;
	margin-bottom: 5px;
	overflow: overlay;
}

.dashboard-user-company {
	height: 16px;
	font-family: 'Roboto';
	font-size: 12.2px;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.31;
	letter-spacing: 0.4px;
	color: rgba(255, 255, 255, 0.8);
	overflow: overlay;
}

.dashboard-line-separator {
	width: 100%;

	margin: 0;
	opacity: 0.12;
	background-color: #ffffff;
}

.dashboard-items-container {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	padding-left: 5px;
	height: 100%;
	overflow: auto;
}

.dashboard-items-container::-webkit-scrollbar {
	display: none;
}

.dashboard-item {
	display: flex;
	width: 100%;
	align-items: center;
	justify-content: space-between;
	padding-right: 10px;
}

.dashboard-item-icon-and-text {
	display: flex;
	align-items: center;
}

.dashboard-item-text {
	width: 157px;
	height: 20px;
	font-family: 'Roboto';
	font-size: 14.2px;
	font-weight: bold;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.41;
	letter-spacing: 0.25px;
	color: #ffffff;
}

.dashboard-item-text:active {
	color: #adadba;
}

#Surface {
	height: 56px;
	border-radius: 1px;
	color: #ffffff;
	padding: 0 16px;
}

#Surface:active {
	border-radius: 40px;
}

.ripple {
	outline-style: auto;
	outline-color: #3f3f49;
}

.close-button-container {
	padding: 2px 0 0 10px;
}

.hamburger-button {
	width: 18px;
	height: 12px;
}

.dashboard-head-sublist-items {
	padding-left: 0;
	margin-left: 0;
}

.dashboard-head-subitem-text {
	width: 96px;
	height: 16px;
	font-family: Roboto;
	font-size: 12.2px;
	font-weight: bold;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.31;
	letter-spacing: 2px;
	color: rgba(255, 255, 255, 0.8);
}

.dashboard-expand-collapse-button-item {
	margin-right: 10px;
}

.notification-dark-style {
	color: #ffffff !important;
	background-color: rgb(32, 32, 32) !important;
}

.hamburger-icon {
	background: url('../../assets/media/img/hamburger-icon.svg');
	width: 24px;
	height: 24px;
}

.cancel-icon {
	background: url('../../assets/media/img/cancel-icon.svg');
	width: 24px;
	height: 24px;
}

.transaction-icon {
	background: url('../../assets/media/img/transaction.svg');
	width: 24px;
	height: 24px;
}

.orders-icon {
	background: url('../../assets/media/img/orders-icon.svg');
	width: 24px;
	height: 24px;
}

.stock-icon {
	background: url('../../assets/media/img/stock-icon.svg');
	width: 24px;
	height: 24px;
}

.suggestions-icon {
	background: url('../../assets/media/img/suggestions-icon.svg');
	width: 24px;
	height: 24px;
}

.delete-icon {
	background: url('../../assets/media/img/delete-icon.svg');
	width: 24px;
	height: 24px;
}

.exit-icon {
	background: url('../../assets/media/img/exit-icon.svg');
	width: 24px;
	height: 24px;
}

.update-icon {
	background: url('../../assets/media/img/update-icon.svg');
	width: 24px;
	height: 24px;
}

.collapse-icon {
	background: url('../../assets/media/img/arrow-right1.svg');
	width: 24px;
	height: 24px;
}

.expand-icon {
	background: url('../../assets/media/img/arrow-down1.svg');
	width: 24px;
	height: 24px;
}

.drag-handle {
	background: url('../../assets/media/img/drag-icon-24px.svg');
	width: 24px;
	height: 24px;
}

.comments-icon {
	background: url('../../assets/media/img/comments.svg');
	width: 24px;
	height: 24px;
	object-fit: contain;
	cursor: pointer;
}

.back-icon {
	background: url('../../assets/media/img/back-icon.svg');
	width: 24px;
	height: 24px;
	cursor: pointer;
}

.black-back-icon {
	background: url('../../assets/media/img/black-back-arrow.png');
	width: 24px;
	height: 24px;
	cursor: pointer;
}

.sync-orders-icon {
	background: url('../../assets/media/img/sync-orders.svg');
	width: 24px;
	height: 24px;
	object-fit: contain;
}

.navigate-icon {
	background: url('../../assets/media/img/navigate.svg');
	width: 24px;
	height: 24px;
	object-fit: contain;
	background-size: contain;
}

.navigate-icon:active {
	background: url('../../assets/media/img/navigate_active.svg');
	width: 24px;
	height: 24px;
	object-fit: contain;
	background-size: contain;
}

.filter-orders-icon {
	background: url('../../assets/media/img/filter-orders.svg');
	width: 24px;
	height: 24px;
	object-fit: contain;
}

.add-icon {
	background: url('../../assets/media/img/add.svg');
	width: 24px;
	height: 24px;
	object-fit: contain;
}

.account-icon {
	background: url('../../assets/media/img/account.svg');
	width: 24px;
	height: 24px;
	object-fit: contain;
}

.chart-icon {
	background: url('../../assets/media/img/analytics.svg');
	width: 24px;
	height: 24px;
	object-fit: contain;
}

.gps-point-icon {
	background: url('../../assets/media/img/gps_point.svg');
	width: 24px;
	height: 24px;
	object-fit: contain;
}

.export-excel-icon {
	background: url('../../assets/media/img/export-excel.svg');
	width: 24px;
	height: 24px;
	object-fit: contain;
}

.info-icon {
	background: url('../../assets/media/img/info.svg');
	width: 24px;
	height: 24px;
	background-size: contain;
	object-fit: contain;
}

.analytics-icon {
	background: url('components/Icons/AnalyticsIcon/analytics.svg');
	width: 24px;
	height: 24px;
	object-fit: contain;
}

.supervisor-icon {
	background: url('components/Icons/SupervisorIcon/supervisor.svg');
	width: 24px;
	height: 24px;
	object-fit: contain;
}

.articles-icon {
	background: url('components/Icons/ArticlesIcon/articles.svg');
	width: 24px;
	height: 24px;
	object-fit: contain;
}

.billings-icon {
	background: url('components/Icons/BillingIcon/billing.svg');
	width: 24px;
	height: 24px;
	object-fit: contain;
}

.reports-icon {
	background: url('components/Icons/ReportIcon/reports.svg');
	width: 24px;
	height: 24px;
	object-fit: contain;
}

.series-icon {
	background: url('components/Icons/SerieIcon/series.svg');
	width: 24px;
	height: 24px;
	object-fit: contain;
}

.warehouse-icon {
	background: url('components/Icons/WarehouseIcon/warehouse.svg');
	width: 24px;
	height: 24px;
	object-fit: contain;
}

.marketplace-icon {
	background: url('components/Icons/MarketplaceIcon/marketplace.svg');
	width: 24px;
	height: 24px;
	object-fit: contain;
}

@media (orientation: portrait) {
	.dashboard {
		min-height: 100%;
	}
}

@media (min-width: 1025px) {
	.dashboard {
		min-height: 100%;
	}
}

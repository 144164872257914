.toolbar-title {
	display: inline;
	width: 216px;
	height: 24px;
	font-family: 'Roboto';
	font-size: 20.2px;
	font-weight: 500;
	font-style: normal;
	font-stretch: normal;
	line-height: normal;
	letter-spacing: 0.25px;
	color: #ffffff;
}

.select-searchBy {
	width: 180px;
	margin: 0 5px 5px 5px;
}

#toolbar-search-order-container {
	margin: 0;
	width: 100%;
}

#toolbar-search-order-input {
	font-family: 'Roboto';
	font-size: 16px;
	font-weight: bold;
	font-style: normal;
	font-stretch: normal;
	line-height: normal;
	letter-spacing: 0.25px;
	color: #ffffff;
}

.warehouses-create-items-popover-list {
	list-style: none;
	padding: 7px;
	color: white;
	margin: 0;
}

.warehouses-create-items-popover-list-item {
	font-family: Roboto;
	height: 30px;
	padding: 5px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	cursor: pointer;
	color: white;
}

.warehouses-create-items-popover-list-item:hover {
	color: lightseagreen;
}

.warehouses-create-items-popover-list-item:active {
	color: lightskyblue;
}

.warehouses-create-items-popover-list-item-selected {
	background-color: #3f3f49;
}

#toolbar-right-side-container {
	justify-content: flex-end;
}

.disabled-item {
	color: gray;
	padding: 5px;
}

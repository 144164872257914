.desktop-stock-table {
	background-color: rgb(63, 63, 73);
	border-radius: 1px;
}

.desktop-stock-table-header {
	background-color: rgb(63, 63, 73);
	border-radius: 1px;
	font-family: Roboto;
	font-size: 11px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.41;
	letter-spacing: 0.25px;
	color: rgba(255, 255, 255, 0.6);
	text-transform: capitalize;
}

.desktop-stock-table-row {
	display: flex;
	align-items: center;
	cursor: pointer;
	border-bottom: 0.4px rgba(224, 224, 225, 0.7) solid;
	font-size: 11px;
	font-family: Roboto;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.41;
	letter-spacing: 0.25px;
	color: rgba(255, 255, 255, 0.6);
}

.desktop-stock-table-row:hover {
	font-size: 12px;
	font-weight: bold;
}

.desktop-stock-table-row:active {
	font-size: 12px;
	font-weight: bold;
}

.desktop-stock-table-index-cell-container {
	display: flex;
	align-items: center;
}

.desktop-stock-table-name-cell-container {
	height: 40px;
	display: flex;
	align-items: center;
	color: rgba(255, 255, 255, 0.6);
}

.desktop-stock-table-name-cell-container:hover {
	color: white;
}

.desktop-stock-flag-priority {
	min-width: 5px;
	height: 30px;
	margin-right: 10px;
}

#stock_serie-meta {
	padding: 10px;
}

.stock_serie-container-data-input {
	display: flex;
	justify-content: space-between;
	margin: 0;
	padding: 0 0 10px 0;
}

.stock_serie-data {
	width: 50%;
	display: flex;
	flex-direction: column;
	margin: 0;
	padding: 0 0 10px 0;
}

.stock_serie-container-title-card {
	display: flex;
	flex-direction: column;
	margin-bottom: 5px;
}

.stock_serie-body-container-item {
	display: flex;
	flex-wrap: wrap;
	width: 73vw;
}

.stock_serie-body-concept-item {
	font-size: 12px;
	font-family: 'Roboto';
	color: lightseagreen;
	margin-right: 10px;
}

.stock_serie-body-value-item-serie {
	color: darkgrey;
}

.stock_serie-body-value-item-serie:active {
	color: lightgray;
}

.stock_serie-card-creator {
	font-size: 13px;
	font-family: 'Roboto';
	color: white;
}

.stock_serie-card-created_at {
	font-family: 'Roboto';
	font-size: 12px;
	color: darkgray;
}
